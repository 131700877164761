@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

* {
  padding: 0;
  margin: 0;
}

html,body, #root {
  height: 100vh;
  overflow: auto;
  background-color: #F5F5F5;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}

.ant-table-wrapper .ant-table-cell {
  padding: 12px !important;
}
